//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-96:_8364,_4760,_7980,_5964,_8428,_5536,_800,_7892;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-96')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-96', "_8364,_4760,_7980,_5964,_8428,_5536,_800,_7892");
        }
      }catch (ex) {
        console.error(ex);
      }