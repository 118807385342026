
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function alphabeticalContainerRT () {
    function repeatAlphabeticalLetters1(alphabeticalLetters, alphabeticalLettersIndex) {
        return [alphabeticalLetters(function () {
                return _createElement('div', {
                    'className': 'cm_vehicle-categories__letters-letter' + (this.currentLetter === this.letter ? ' active' : '') + (this.isDisabled ? ' disabled' : ''),
                    'title': this.letter,
                    'onClick': () => this.setCurrentLetter(this.letter)
                }, '\n  ', this.letter, '\n');
            }, { count: undefined })];
    }
    function repeatAlphabeticalGroups2(view, alphabeticalGroups, alphabeticalGroupsIndex) {
        return [alphabeticalGroups(function () {
                function repeatTiles1(tiles, tilesIndex) {
                    return [tiles(function () {
                            return _createElement('a', {
                                'className': 'cm_vehicle-categories_category-container cm_vehicle-categories_link',
                                'href': this.href
                            }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container cm_vehicle-categories_link' }, this.imageUrl ? _createElement('img', {
                                'className': 'cm_vehicle-categories_category-image',
                                'src': this.imageUrl,
                                'alt': this.value,
                                'key': '214'
                            }) : null, !this.imageUrl ? _createElement('h3', { 'key': '340' }, this.value) : null), _createElement('span', { 'className': 'cm_vehicle-categories_category-title' }, this.value));
                        }, { count: undefined })];
                }
                function repeatTiles2(tiles, tilesIndex) {
                    return [tiles(function () {
                            return _createElement('a', {
                                'className': 'cm_vehicle-categories_category-wrapper-title-link',
                                'href': this.href,
                                'title': this.value
                            }, '\n          ', this.value, '\n        ');
                        }, { count: undefined })];
                }
                return this.tiles.length ? _createElement('div', {
                    'className': 'cm_vehicle-categories_category-wrapper',
                    'key': '0'
                }, view === 'grid' ? [
                    _createElement('h1', { 'key': '821' }, this.letter),
                    _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_vehicle-categories_category-repeater  cmRepeater_tiles',
                            'key': '823'
                        },
                        _map(this.tiles, repeatTiles1.bind(this))
                    ])
                ] : null, view === 'columns' ? [
                    _createElement('h3', { 'key': '11561' }, this.letter),
                    _createElement('div', {
                        'className': 'cm_vehicle-categories_category-repeater-container',
                        'key': '11563'
                    }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-categories_category-repeater  cmRepeater_tiles' },
                        _map(this.tiles, repeatTiles2.bind(this))
                    ]))
                ] : null) : null;
            }, { count: undefined })];
    }
    function scopeView3() {
        var view = this.view;
        return _createElement.apply(this, [
            'div',
            { 'className': 'cm_vehicle-categories_category-wrapper-repeater' + (this.currentLetter !== 'All' ? ' filtered' : '') + '  cmRepeater_alphabeticalGroups' },
            _map(this.alphabeticalGroups, repeatAlphabeticalGroups2.bind(this, view))
        ]);
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories cm_vehicle-categories__alphabetical cm_vehicle-categories__alphabetical-' + this.view }, [this.vehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n        ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n      ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                            'className': 'index',
                            'key': '118'
                        }, this.index + 1) : null, _createElement.apply(this, [
                            'select',
                            {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.firstSelectedTerm ? this.firstSelectedTerm : '',
                                'aria-selected': this.selectedEntries.length ? true : false,
                                'disabled': this.disabled
                            },
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n        ', this.title, '\n      ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            _map(this.entries, repeatEntry1.bind(this))
                        ])));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__vehicle-categories cm_vehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '86'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear btn btn--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle
            }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '25130'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '3034'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button btn btn--primary',
                'onClick': this.changeVehicle
            }, '\n      Change\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button btn btn--secondary',
                'onClick': this.discardVehicle
            }, '\n      Clear\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories__letters cmRepeater_alphabeticalLetters' },
        _map(this.alphabeticalLetters, repeatAlphabeticalLetters1.bind(this))
    ]), scopeView3.apply(this, []));
}
        export const componentNames = ["cm:vehicleWidget"]